.quiz--question__item {
    border: 1px solid #dfdfdf;
    margin-bottom: 12px;
    user-select: none;
    border-radius: 5px;
    display: flex;
    overflow: hidden;
    flex-direction: column;
  
  
    &.open {
      .question--body, .canswers {
        display: block;
      }
    }
  
    .question--item__header {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 4px;
      border-bottom: 1px solid #dfdfdf;
      background: #111;
      color: #FFF;
    }
    .question--body {
      padding: 4px;
      display: none;
    }
  
    .canswers {
      display: none;
    }
  
    .header--block {
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
  
    }
    .item--title {
      font-weight: 500;
      padding-left: 12px;
    }
    .sortable__item--delete {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
  
  .question--answers {
    padding: 4px;
    margin: 4px;
    border: 1px solid #dfdfdf;
    border-radius: 5px;
  }
  
  .quiz--answer {
    margin-bottom: 12px;
    background: #dfdfdf;
    padding: 12px;
    border-radius: 5px;
    & + .quiz--answer {
      padding-top: 12px;
    }
    .answer--props {
      display: flex;
      flex-direction: row;
      align-items: center;
      .input {
        margin-left: 8px;
        margin-right: 8px;
        flex: 1;
      }
      margin-bottom: 4px;
    }
    .ant-btn-danger {
      margin-left: 12px;
    }
  }
  
  .qimage {
    position: relative;
    max-width: 320px;
    width: 100%;
    border-radius: 5px;
    overflow: hidden;
    margin: 8px 0;
    img {
      width: 100%;
      height: auto;
    }
    button {
      position: absolute;
      z-index: 2;
      top: 10px;
      right: 10px;
    }
  }
  
  .ant-table-body {
    .qimage {
      max-width: 120px;
      height: auto;
      img {
        width: 100%;
        height: auto;
      }
    }
  }