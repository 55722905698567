.contacts__view {
	padding: 12px;
}

.contacts--filter {
	margin-bottom: 24px;
	display: flex;
	flex-direction: row;
	align-items: center;
	> button {
		margin-right: 12px;
	}
}

.contacts--list {
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 12px 12px;
	margin: 12px 0;
	.cvalues {
		margin-left: 12px;
	}
	.clist {
		display: flex;
		flex-direction: row;
		width: 100%;
		flex-wrap: wrap;
		margin-top: 12px;
	}
	.contact--item {
		display: flex;
		flex-direction: row;
		background-color: #FFF;
		box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
		border-radius: 4px;
		.photo {
			width: 180px;
			height: 180px;
			background-size: cover;
			background-position: center;
		}
		.item__main--info {
			flex: 1;
			flex-direction: column;
			.tags {
				margin-top: 12px;
			}
			padding: 0 12px 6px;
			.item__wrapper {
				flex: 1;
				flex-direction: column;
			}
			.title {
				font-size: 1.4rem;
				font-weight: 500;
			}
			.lead {
				color: #101010;
				margin-bottom: 8px;
			}
			.comment {
				color: grey;
				font-size: 12px;
				margin-bottom: 8px;
			}
		}
		.item__actions{
			flex-direction: column;
			display: flex;
			align-items: center;
			justify-content:center;
			padding: 6px;
			button + button {
				margin-top: 12px;
			}
		}
	}
}

.contact__block--row {
	display: flex;
	flex-direction: row;
	align-items: center;

	.ant-form-item-control, .ant-form-item {
		width: 100%;
	}
	.ant-form-item-children {
		display: flex;
		flex-direction: row;
		align-items: center;
		width: 100%;
		.ant-select {
			min-width: 230px;
			max-width: 230px;
		}
		input {
			width: 100%;
		}
		> *  + * {
			margin-left: 20px;
		}
	}
}

.contacts__form--block {
	border: 1px solid rgba(0, 0, 0, 0.15);
	padding: 15px;
	margin-bottom: 20px;
}